import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    reporteAcarreoQr: [],
    reporteAcarreoQrV2: [],
    reporteBop: [],
    reporteEquiposSiniestros: [],
    reporteAndinaSupermercado: [],
    reporteAndinaSupermercadoV2: [],
    reporteTransportesRepetidos: [],
    reporteGenericoVeralia: []
  },
  mutations: {
    set_accion_reporteAcarreoQr (state, reporteAcarreoQr) {
      state.reporteAcarreoQr = reporteAcarreoQr
    },
    set_accion_reporteAcarreoQrV2 (state, reporteAcarreoQrV2) {
      state.reporteAcarreoQrV2 = reporteAcarreoQrV2
    },
    set_accion_reporteBop (state, reporteBop) {
      state.reporteBop = reporteBop
    },
    set_accion_reporteEquiposSiniestros (state, reporteEquiposSiniestros) {
      state.reporteEquiposSiniestros = reporteEquiposSiniestros
    },
    set_accion_reporteAndinaSupermercado (state, reporteAndinaSupermercado) {
      state.reporteAndinaSupermercado = reporteAndinaSupermercado
    },
    set_accion_reporteAndinaSupermercadoV2 (state, reporteAndinaSupermercadoV2) {
      state.reporteAndinaSupermercadoV2 = reporteAndinaSupermercadoV2
    },
    set_accion_reporteTransportesRepetidos (state, reporteTransportesRepetidos) {
      state.reporteTransportesRepetidos = reporteTransportesRepetidos
    },
    set_accion_reporteGenericoVeralia (state, reporteGenericoVeralia) {
      state.reporteGenericoVeralia = reporteGenericoVeralia
    }
  },
  actions: {
    async getReportePorCargaAcarreoQR ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getReportePorCargaAcarreoQR`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReportePorCargaAcarreoQR')
          // console.log(response.data)
          commit('set_accion_reporteAcarreoQr', response.data)
          return true
        } else {
          console.log('por else getReportePorCargaAcarreoQR')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReportePorCargaAcarreoQR')
        router.push({ path: '/' })
        return false
      }
    },
    async getReportePorCargaAcarreoQRV2 ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/reportes/qr`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReportePorCargaAcarreoQR')
          // console.log(response.data)
          commit('set_accion_reporteAcarreoQrV2', response.data)
          return true
        } else {
          console.log('por else getReportePorCargaAcarreoQRV2')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReportePorCargaAcarreoQR')
        router.push({ path: '/' })
        return false
      }
    },
    async getReporteBop ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getReporteBop`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReporteBop')
          // console.log(response.data)
          commit('set_accion_reporteBop', response.data)
          return true
        } else {
          console.log('por else getReporteBop')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReporteBop')
        router.push({ path: '/' })
        return false
      }
    },
    async getReporteEquiposSiniestros ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getReporteAdminEquipoSinistros`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReporteEquiposSiniestros')
          // console.log(response.data)
          commit('set_accion_reporteEquiposSiniestros', response.data)
          return true
        } else {
          console.log('por else getReporteEquiposSiniestros')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReporteEquiposSiniestros')
        router.push({ path: '/' })
        return false
      }
    },
    async getReporteAndinaSupermercado ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getReporteAndinaSupermercado`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReporteAndinaSupermercado')
          // console.log(response.data)
          commit('set_accion_reporteAndinaSupermercado', response.data)
          return true
        } else {
          console.log('por else getReporteAndinaSupermercado')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReporteAndinaSupermercado')
        router.push({ path: '/' })
        return false
      }
    },
    async getReporteAndinaSupermercadoV2 ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/reportes/andinaSupermercado`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReporteAndinaSupermercadoV2')
          // console.log(response.data)
          commit('set_accion_reporteAndinaSupermercadoV2', response.data)
          return true
        } else {
          console.log('por else getReporteAndinaSupermercadoV2')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReporteAndinaSupermercadoV2')
        router.push({ path: '/' })
        return false
      }
    },
    async getTransportesRepetidos ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getReporteRepetidos`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getTransportesRepetidos')
          // console.log(response.data)
          commit('set_accion_reporteTransportesRepetidos', response.data)
          return response.data
        } else {
          console.log('por else getTransportesRepetidos')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getTransportesRepetidos')
        // router.push({ path: '/' })
        return false
      }
    },
    async getReporteGenericoVeralia ({ commit, dispatch, state }, data) {
      data.cliente = 'Veralia'
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getReporteGenerico`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReporteGenericoVeralia')
          // console.log(response.data)
          commit('set_accion_reporteGenericoVeralia', response.data)
          return response.data
        } else {
          console.log('por else getReporteGenericoVeralia')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReporteGenericoVeralia')
        // router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
