import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    transportes: [],
    camiones: [],
    conductores: [],
    ayudantes: [],
    transportes2: ['transporte2', 'transporte4'],
    transportesNoAsignados: [],
    listaTransportesStore: [],
    transportesPage: [],
    vueltasClientes: [],
    contadorTransportes: [],
    fields: []
    // host: 'localhost:8090'
  },
  mutations: {
    set_accion_vueltasClientes (state, vueltasClientes) {
      state.vueltasClientes = vueltasClientes
    },
    set_accion_transportesPage (state, transportesPage) {
      state.transportesPage = transportesPage
    },
    set_accion_transportes (state, transporte) {
      state.transportes = transporte
    },
    set_accion_camiones (state, camiones) {
      state.camiones = camiones
    },
    set_accion_conductores (state, conductores) {
      state.conductores = conductores
    },
    set_accion_ayudantes (state, ayudantes) {
      state.ayudantes = ayudantes
    },
    set_accion_contadorTransportes (state, contadorTransportes) {
      state.contadorTransportes = contadorTransportes
    },
    set_accion_fields (state, fields) {
      fields.sort((a, b) => a.field_order - b.field_order)
      state.fields = fields
    }
  },
  actions: {
    async getVueltasCliente ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/vueltas/vueltas-cliente?id_vuelta_principal=${data.id_vuelta_principal}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getEntregaDiariaPage')
          // console.log(response.data)
          // console.log(response.data)
          commit('set_accion_vueltasClientes', response.data)
          return true
        } else {
          console.log('por else getVueltasCliente')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getVueltasCliente')
        // router.push({ path: '/' })
        return false
      }
    },
    async getTransportesPage ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getTransportes/form/${data.form_code}?page=${data.page}&size=${data.size}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data.periodos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getEntregaDiariaPage')
          // console.log(response.data)
          commit('set_accion_transportesPage', response.data)
          return true
        } else {
          console.log('por else getTransportesPage')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getTransportesPage')
        // router.push({ path: '/' })
        return false
      }
    },
    async getTransportesFilterPage ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getTransportesFilter?page=${data.page}&size=${data.size}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data.filter
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getEntregaDiariaPage')
          // console.log(response.data)
          commit('set_accion_transportesPage', response.data)
          return true
        } else {
          console.log('por else getTransportesPage')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getTransportesPage')
        // router.push({ path: '/' })
        return false
      }
    },
    async getTransportesV2FilterPage ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getTransportesV2Filter?page=${data.page}&size=${data.size}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          // console.log('success stuff api getEntregaDiariaPage')
          // console.log(response.data)
          commit('set_accion_transportesPage', response.data)
          return true
        } else {
          console.log('por else getTransportesV2FilterPage')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getTransportesV2FilterPage')
        // router.push({ path: '/' })
        return false
      }
    },
    async getTransportes ({ commit, dispatch, state }, periodo) {
      // console.log(store.state.token)
      const date = new Date()
      const primerDia = new Date(date.getFullYear(), date.getMonth(), 1)
      const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      console.log('primer dia del mes: ' + primerDia)
      console.log('ultimo dia del mes: ' + ultimoDia)
      const fechaActual = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')}T${
              date.getHours().toString().padStart(2, '0')}:${
                date.getMinutes().toString().padStart(2, '0')}:${
                  date.getSeconds().toString().padStart(2, '0')}.000+00:00`
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')}T${
              '00'}:${
                '00'}:${
                  '00'}.000+00:00`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')}T${
              '23'}:${
                '59'}:${
                  '59'}.000+00:00`
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const primerDiaFormat = `${
        primerDia.getFullYear().toString().padStart(4, '0')}-${
          (primerDia.getMonth() + 1).toString().padStart(2, '0')}-${
            primerDia.getDate().toString().padStart(2, '0')}T${
              primerDia.getHours().toString().padStart(2, '0')}:${
                primerDia.getMinutes().toString().padStart(2, '0')}:${
                  primerDia.getSeconds().toString().padStart(2, '0')}.000+00:00`
      const ultimoDiaFormat = `${
        ultimoDia.getFullYear().toString().padStart(4, '0')}-${
          (ultimoDia.getMonth() + 1).toString().padStart(2, '0')}-${
            ultimoDia.getDate().toString().padStart(2, '0')}T${
              '23'}:${
                '59'}:${
                  ultimoDia.getSeconds().toString().padStart(2, '0')}.000+00:00`
      console.log(fechaActual)
      console.log(primerDiaFormat)
      console.log(ultimoDiaFormat)
      // formato de fecha 2022-04-08T23:16:51.000+00:00
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/getTransportes`,
          data: {
            // fechaDesde: '2022-08-19T00:00:00.000+00:00',
            // fechaHasta: '2022-08-19T23:00:00.000+00:00'
            fechaDesde: periodo.fechaActualPrimeraHora,
            fechaHasta: periodo.fechaActualUltimaHora
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api transporte')
          console.log(response.data)
          commit('set_accion_transportes', response.data)
          return true
        } else {
          // commit('set_token', 'clave o usuario invalido')
          console.log('por else transporte')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch transporte')
        // router.push({ path: '/' })
        // dispatch('GetActividadesCoordinador')
        // this.dispatch.GetActividadesCoordinador()
        // commit('set_token', 'clave o usuario invalido')
        return false
      }
    },
    async getConductores ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/getUsers`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api conductores')
          // console.log(response.data)
          commit('set_accion_conductores', response.data)
          return true
        } else {
          console.log('por else conductores')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch conductores')
        // router.push({ path: '/' })
        return false
      }
    },
    async getCamiones ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/getCamion`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api camiones')
          // console.log(response.data)
          commit('set_accion_camiones', response.data)
          return true
        } else {
          console.log('por else camiones')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch camiones')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateAdminCamion ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'put',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/updateAdminCamion/${data.id_camion}?administrador=${data.administrador}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateAdminCamion')
          // console.log(response.data)
          commit('set_accion_camiones', response.data)
          return true
        } else {
          console.log('por else updateAdminCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateAdminCamion')
        // router.push({ path: '/' })
        return false
      }
    },
    async createCamion ({ commit, dispatch, state }, camion) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/createCamion`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: camion
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api createCamion')
          // console.log(response.data)
          commit('set_accion_camiones', response.data)
          return true
        } else {
          console.log('por else createCamion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch createCamion')
        // router.push({ path: '/' })
        return false
      }
    },
    async createCamiones ({ commit, dispatch, state }, camiones) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/createCamiones`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: camiones
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api createCamiones')
          // console.log(response.data)
          commit('set_accion_camiones', response.data)
          return true
        } else {
          console.log('por else createCamiones')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch createCamiones')
        // router.push({ path: '/' })
        return false
      }
    },
    async getAyudantes ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/users/getAyudantes`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api ayudantes')
          // console.log(response.data)
          commit('set_accion_ayudantes', response.data)
          return true
        } else {
          console.log('por else ayudantes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch ayudantes')
        // router.push({ path: '/' })
        return false
      }
    },
    async deleteCamionConductor ({ commit, dispatch, state }, id) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/vps/users/deleteCamionConductor?id=${id}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api deleteCamionConductor')
          // console.log(response.data)
          return true
        } else {
          console.log('por else deleteCamionConductor')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteCamionConductor')
        // router.push({ path: '/' })
        return false
      }
    },
    async updateTransporte ({ commit, dispatch, state }, camiones) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/updateTransportes`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: camiones
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateTransporte')
          // console.log(response.data)
          return response
        } else {
          console.log('por else updateTransporte')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateTransporte')
        // router.push({ path: '/' })
        return err
      }
    },
    async countByEstadoTransportes ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/countByEstadoTransportes`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api countByEstadoTransportes')
          // console.log(response.data)
          commit('set_accion_contadorTransportes', response.data)
          return response
        } else {
          console.log('por else countByEstadoTransportes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch countByEstadoTransportes')
        // router.push({ path: '/' })
        return err
      }
    },
    async confirmacionAdmin ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/confirmacionAdmin`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api confirmacionAdmin')
          // console.log(response.data)
          return response
        } else {
          console.log('por else confirmacionAdmin')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch confirmacionAdmin')
        // router.push({ path: '/' })
        return err
      }
    },
    async getFields ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/vueltas/getFieldsTransporteLastActivity?id_transporte=${data}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getFields')
          // console.log(response.data)
          commit('set_accion_fields', response.data)
          return response
        } else {
          console.log('por else getFields')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getFields')
        // router.push({ path: '/' })
        return err
      }
    },
    async getFieldsByActivityId ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/vps/vueltas/getFieldsActivityId?id_activity=${data}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getFieldsByActivityId')
          // console.log(response.data)
          commit('set_accion_fields', response.data)
          return response
        } else {
          console.log('por else getFieldsByActivityId')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getFieldsByActivityId')
        // router.push({ path: '/' })
        return err
      }
    },
    async updateField ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'put',
          url: `${store.state.host}/api/vps/vueltas/updateField`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateField')
          // console.log(response.data)
          // commit('set_accion_fields', response.data)
          return response
        } else {
          console.log('por else updateField')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateField')
        // router.push({ path: '/' })
        return err
      }
    }
  },
  getters: {
    getterTransportes (state) {
      return state.transportes
    },
    getterFields (state) {
      return state.fields
    }
  }
}
