import { mapActions, mapState } from 'vuex'
import ImagenesView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Visitas_cliente/Imagenes/ImagenesView.vue'
import EditarFieldView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/EditarFieldView.vue'

export default {
  name: 'EditarSiniestroView',
  components: {
    ImagenesView,
    EditarFieldView
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: [],
      activityid: null,
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: '- Conductor' }
      ],
      selectedCamion: null,
      optionsCamion: [
        { value: null, text: '- Camion' }
      ],
      selectedEstado: null,
      optionsEstado: [
        { value: null, text: '- Estado' },
        { value: 'entregada', text: 'entregada' },
        { value: 'no realizado', text: 'no realizado' }
      ],
      conductorId: null,
      camionId: null,
      siniestro_id: null,
      editarModal: {
        id: 'editar-siniestros-modal',
        title: 'Editar Campos',
        content: ''
      },
      fieldView: true,
      fecha: null,
      hora: null,
      fechaHora: null,
      nroComisaria: null,
      parrafo: null,
      folio: null,
      fechaJuzgado: null,
      nroParte: null,
      fechaCitacion: null,
      alcoholemia: null,
      nroCausa: null,
      testigos: null,
      juzgadoEnable: false,
      DenunciaEnable: false,
      siniestroInfoEnable: false,
      poliza: null,
      item: null
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    this.siniestro_id = this.dataJSON.id
    this.activityid = this.dataJSON.activity?.activityid ?? this.dataJSON.activityid
    this.nroComisaria = this.dataJSON.nro_comisaria
    this.parrafo = this.dataJSON.parrafo
    this.folio = this.dataJSON.folio
    this.fechaJuzgado = this.dataJSON.fecha_juzgado ? this.dataJSON.fecha_juzgado.split('T')[0] : null
    this.nroParte = this.dataJSON.nro_parte
    this.fechaCitacion = this.dataJSON.fecha_citacion ? this.dataJSON.fecha_citacion.split('T')[0] : null
    this.alcoholemia = this.dataJSON.alcoholemia
    this.nroCausa = this.dataJSON.nro_causa
    this.testigos = this.dataJSON.testigos
    this.poliza = this.dataJSON.poliza
    this.item = this.dataJSON.item
    this.getFechaHora(this.dataJSON.fecha)
    this.getCamiones().then((res) => {
      for (const camion in this.camiones) {
        this.optionsCamion.push({ value: this.camiones[camion].id, text: this.camiones[camion].matricula + ' / ' + this.camiones[camion].cam_basis })
      }
      this.optionsCamion.sort((a, b) => a.text.localeCompare(b.text))
    })
    this.getFieldsTallerSiniestros(this.siniestro_id).then((res) => {
      console.log('fields siniestro: ' + this.siniestro_id)
    })
    this.EditarEnable()
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getCamiones'
    ]),
    ...mapActions('Siniestros', [
      'getSiniestros', 'updateEstadoSiniestro', 'deleteSiniestro', 'getEstadosSiniestros', 'updateSiniestro', 'getFieldsTallerSiniestros'
    ]),
    modalEditarsEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      this.editarModal.id = 'editar-modal' + Date.now()
      const data = {
        activity: {
          activityid: this.activityid
        }
      }
      this.editarModal.content = JSON.stringify(data)
      this.$root.$emit('bv::show::modal', this.editarModal.id, button)
    },
    EditarEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      const data = {
        activity: {
          activityid: this.activityid
        }
      }
      // console.log(data)
      this.editarModal.content = JSON.stringify(data)
    },
    hideModalEditar () {
      this.$refs[this.editarModal.id].hide()
    },
    emitEditarField (data) {
      console.log('emitEditarSiniestroField')
      this.hideModalEditar()
    },
    editar () {
      const data = {}
      data.id = this.siniestro_id
      const updateFecha = this.fecha + ' ' + this.hora
      if (this.fechaHora !== updateFecha) {
        data.fecha = updateFecha
      }
      data.nro_comisaria = this.nroComisaria
      data.parrafo = this.parrafo
      data.folio = this.folio
      if (this.fechaJuzgado !== null && this.fecha_juzgado !== '') data.fecha_juzgado = this.fechaJuzgado + ' 00:00:00'
      data.nro_parte = this.nroParte
      if (this.fechaCitacion !== null && this.fechaCitacion !== '') data.fecha_citacion = this.fechaCitacion + ' 00:00:00'
      // data.fechaCitacion = this.fecha_citacion
      data.alcoholemia = this.alcoholemia
      data.nro_causa = this.nroCausa
      data.testigos = this.testigos
      data.poliza = this.poliza
      data.item = this.item
      console.log(data)
      this.updateSiniestro(data).then((res) => {
        const toast = {}
        toast.title = 'Siniestro Actualizado Con Exito.'
        toast.variant = 'success'
        toast.body = 'Exito.'
        this.makeToast(toast)
        this.$emit('emitEditarSiniestro', data)
      })
    },
    getFechaHora (data) {
      // data = '2023-10-14T03:06:35.000+00:00'
      // Crear un objeto Date a partir de la cadena
      const dateObj = new Date(data)
      // Obtener la fecha en el formato "yyyy-mm-dd"
      const year = dateObj.getFullYear()
      const month = String(dateObj.getMonth() + 1).padStart(2, '0') // Agregar ceros a la izquierda si es necesario
      const day = String(dateObj.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      // Obtener la hora en el formato "hh:mm:ss"
      const hours = String(dateObj.getUTCHours()).padStart(2, '0')
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0')
      const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0')
      const formattedTime = `${hours}:${minutes}:${seconds}`
      // console.log('Fecha:', formattedDate)
      // console.log('Hora:', formattedTime)
      this.fecha = formattedDate
      this.hora = formattedTime
      this.fechaHora = formattedDate + ' ' + formattedTime
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('Siniestros', ['fieldsTaller']),
    circleClass () {
      // Comprobamos si la lista está vacía
      if (this.fieldsTaller.length === 0) {
        return 'circle-red'
      }
      // Nombres de los campos requeridos
      const requiredFields = ['V12FecTall', 'V12AdmFlota', 'V12ValSuper', 'V12FecCamOk', 'V12NomTall']
      // Verificamos que cada campo requerido tenga un valor no nulo y no vacío
      const allFieldsValid = requiredFields.every(fieldName => {
        const field = this.fieldsTaller.find(item => item.field_name === fieldName)
        return field && field.field_value // true si existe y no es null/vacío
      })
      // Retornamos el color basado en la validación
      if (allFieldsValid) {
        return 'circle-green'
      } else {
        return 'circle-yellow'
      }
    }
  }
}
