import { mapActions, mapState } from 'vuex'
import EditarAndinaSupermercadoView from '@/components/Layout/Menu/Gestores/Andina_supermercado/Editar_andina_supermercado/EditarAndinaSupermercadoView.vue'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
  name: 'GestorAndinaSupermercadoView',
  components: {
    EditarAndinaSupermercadoView,
    FiltroReportes
  },
  data: function () {
    return {
      optionEstadosAndinaSupermercado: [
        { value: null, text: '- Estados' }
      ],
      items: [],
      fields: [
        { key: 'id', label: 'id', sortable: true, sortDirection: 'desc' },
        { key: 'nro_transporte', label: 'Nro Transporte', sortable: true, sortDirection: 'desc' },
        { key: 'conductor', label: 'Conductor', sortable: true, sortDirection: 'desc' },
        { key: 'patente', label: 'Patente', sortable: true, sortDirection: 'desc' },
        { key: 'cam_basis', label: 'Cam Basis', sortable: true, sortDirection: 'desc' },
        { key: 'fecha', label: 'Fecha', sortable: true, sortDirection: 'desc' },
        { key: 'estado', label: 'Estado', sortable: true, sortDirection: 'desc' },
        { key: 'editar', label: 'Editar', sortable: true, sortDirection: 'desc' }
      ],
      totalRows: 1,
      currentPage: 1,
      page: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Muchos Registros' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      editarModal: {
        id: 'modal-editar-andinaSupermercado-filds',
        title: 'Editar Andina Supermercado',
        content: ''
      },
      estadosAndinaSupermercadoRegistros: {},
      dataFiltroReporte: null,
      filterAndinaSupermercado: {}
    }
  },
  props: {
  },
  watch: {
    dataFiltroReporte: function (val) {
      console.log('observador dataFiltroReporte')
      console.log(this.dataFiltroReporte)
      console.log(this.PeriodoFechaActual())
      const data = this.nextPage()
      this.findAndinaSupermercado(data)
    },
    perPage: function (val) {
      console.log('observador perPage')
      console.log(this.PeriodoFechaActual())
      this.page = 1
      const data = this.nextPage()
      this.findAndinaSupermercado(data)
    }
  },
  mounted () {
    console.log('Andina Supermercado')
    this.filterAndinaSupermercado = this.PeriodoFechaActual()
    const data = this.nextPage()
    this.findAndinaSupermercado(data)
    this.getEstadosAndinaSupermercado().then((res) => {
      console.log(this.estadosAndinaSupermercado)
      for (const e in this.estadosAndinaSupermercado) {
        this.optionEstadosAndinaSupermercado.push({ value: this.estadosAndinaSupermercado[e], text: this.estadosAndinaSupermercado[e] })
      }
      console.log(this.optionEstadosAndinaSupermercado)
    })
  },
  methods: {
    ...mapActions('Siniestros', [
      'getSiniestros', 'updateEstadoSiniestro', 'deleteSiniestro', 'getEstadosSiniestros', 'getSiniestrosFilter'
    ]),
    ...mapActions('AndinaSupermercado', [
      'getAndinaSupermercados', 'updateEstadoAndinaSupermercado', 'updateAndinaSupermercado', 'deleteAndinaSupermercado', 'getEstadosAndinaSupermercado', 'getAndinaSupermercadoFilter', 'getAndinaSupermercadoReports'
    ]),
    getFieldValue (fields, fieldName) {
      const field = fields.find(f => f.field_name === fieldName)
      return field ? field.field_value : ''
    },
    findAndinaSupermercado (data) {
      if (this.dataFiltroReporte !== null) {
        data.fechaDesde = this.dataFiltroReporte.fechaDesde
        data.fechaHasta = this.dataFiltroReporte.fechaHasta
        data.estado = this.dataFiltroReporte.estadoAndinaSupermercado
        data.conductor = this.dataFiltroReporte.conductor
        data.patente = this.dataFiltroReporte.patente
        data.administrador = this.dataFiltroReporte.administrador
      } else {
        const date = this.PeriodoFechaActual()
        data.fechaDesde = date.fechaDesde
        data.fechaHasta = date.fechaHasta
      }
      this.getAndinaSupermercadoFilter(data).then((res) => {
        console.log(this.andinaSupermercados)
        this.items = []
        this.items = this.andinaSupermercados.content
        this.creaListaEstadosAndinaSupermercado()
      })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlePageChange (value) {
      this.page = value
      const data = this.nextPage()
      this.findAndinaSupermercado(data)
    },
    nextPage () {
      const data = {
        page: (this.page - 1),
        size: this.perPage
      }
      return data
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    modalEditarsEnable (item, index, button) {
      // this.visitasModal.title = `Row index: ${index}`
      this.editarModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarModal.id, button)
    },
    hideModalEditar () {
      this.$refs[this.editarModal.id].hide()
    },
    creaListaEstadosAndinaSupermercado () {
      console.log('creaListaEstadosAndinaSupermercado')
      console.log(this.items)
      this.estadosAndinaSupermercadoRegistros = {}
      for (const estado in this.items) {
        this.estadosAndinaSupermercadoRegistros[this.items[estado].id] = this.items[estado].estado
      }
      console.log(this.estadosAndinaSupermercadoRegistros)
    },
    actualizaEstadoAndinaSupermercado (item) {
      console.log('actualizaEstadoAndinaSupermercado')
      console.log(item)
      console.log(this.estadosAndinaSupermercadoRegistros[item.id])
      const data = {
        id_andinaSupermercado: item.id,
        estado: this.estadosAndinaSupermercadoRegistros[item.id]
      }
      this.updateEstadoAndinaSupermercado(data).then((res) => {
      })
    },
    emitEditarAndinaSupermercado () {
      const data = this.nextPage()
      this.findAndinaSupermercado(data)
      this.getEstadosAndinaSupermercado().then((res) => {
        console.log(this.estadosAndinaSupermercado)
        for (const e in this.estadosAndinaSupermercado) {
          if (e !== undefined && this.estados !== undefined) this.optionEstadosAndinaSupermercado.push({ value: this.estados[e], text: this.estados[e] })
        }
        console.log(this.optionEstadosAndinaSupermercado)
      })
      // this.hideModalEditar()
    },
    PeriodoFechaActual () {
      const date = new Date()
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      console.log(fechaActualPrimeraHora)
      console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaDesde = fechaActualPrimeraHora
      periodo.fechaHasta = fechaActualUltimaHora
      return periodo
    },
    exportarExcel () {
      console.log('exportar excel')
      const itemsAux = []
      let items = {}
      const data = {}
      if (this.dataFiltroReporte !== null) {
        data.fechaDesde = this.dataFiltroReporte.fechaDesde
        data.fechaHasta = this.dataFiltroReporte.fechaHasta
        data.estado = this.dataFiltroReporte.estadoAndinaSupermercado
        data.administrador = this.dataFiltroReporte.administrador
      } else {
        const date = this.PeriodoFechaActual()
        data.fechaDesde = date.fechaDesde
        data.fechaHasta = date.fechaHasta
      }
      this.getAndinaSupermercadoReports(data).then((res) => {
        console.log(this.andinaSupermercadoReports)
        for (const item in this.andinaSupermercadoReports) {
          items = {}
          for (const [key, value] of Object.entries(this.andinaSupermercadoReports[item])) {
            if (!key.includes('_cellVariants')) {
              items[key] = value
            }
          }
          itemsAux.push(items)
        }
        const reorderObject = obj => {
          const fechaCompleta = new Date(obj.fecha) // Convierte la fecha a un objeto Date
          const fechaSolo = fechaCompleta.toISOString().split('T')[0] // Extrae solo la fecha
          const horaSolo = fechaCompleta.toTimeString().split(' ')[0] // Extrae solo la hora
          return {
            'Ingrese Número Transporte:': obj['Ingrese Número Transporte:'],
            'Ingrese Foto de Guía de Despacho:': obj['Ingrese Foto de Guía de Despacho:'],
            estado: obj.estado,
            patente: obj.patente,
            fecha_hora: obj.fecha,
            fecha: fechaSolo,
            hora: horaSolo,
            Observaciones: obj['Observaciones:'],
            id: obj.id,
            conductor: obj.conductor,
            rut_conductor: obj.rut_conductor,
            cod_buk_conductor: obj.cod_buk_conductor,
            ayudante1: obj.ayudante1,
            rut_ayudante1: obj.rut_ayudante1,
            cod_buk_ayudante1: obj.cod_buk_ayudante1,
            ayudante2: obj.ayudante2,
            rut_ayudante2: obj.rut_ayudante2,
            cod_buk_ayudante2: obj.cod_buk_ayudante2,
            ayudante3: obj.ayudante3,
            rut_ayudante3: obj.rut_ayudante3,
            cod_buk_ayudante3: obj.cod_buk_ayudante3
          }
        }
        const reorderedData = itemsAux.map(reorderObject)
        const ws = XLSX.utils.json_to_sheet(reorderedData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'andina_supermercado')
        XLSX.writeFile(wb, 'andina_supermercado.xlsx')
      })
    }
  },
  computed: {
    ...mapState('Siniestros', ['siniestros']),
    ...mapState('AndinaSupermercado', ['estadosAndinaSupermercado']),
    ...mapState('AndinaSupermercado', ['andinaSupermercadoReports']),
    ...mapState('AndinaSupermercado', ['andinaSupermercados']),
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
